
import { computed, defineComponent, reactive, PropType } from "vue";
import { ExamReportDTO } from "@/dtos/reports/ExamReportDTO";
import { formatDate2, formatTime, diffTime, formatDateTime } from "@/utils/meus-exames/datetime";
import RadioButtonBasic from "@/components/RadioButtonBasic.vue";
import { maska } from "maska";
import ProctoringResponseDTO from "@/dtos/ProctoringResponseDTO";
import Irregularities from "./Irregularities.vue";
import Irregularity from "./Irregularity.vue";



const RelatorioTecnicoComp = defineComponent({
    components: {
        RadioButtonBasic,
        Irregularities,
        Irregularity
    },
    directives: { maska },
    props: {
        report: {
            type: Object as PropType<ExamReportDTO>,
            required: true
        },
        record:{
            type: Object as PropType<ProctoringResponseDTO>,
            required: true
        },

    },
    methods: {
        
        getLetters(letters: any, choosedAnswer: any, index: number) {
            var choosedAnswerReturn = letters[choosedAnswer - 1];
            if (choosedAnswerReturn) {
                return choosedAnswerReturn;
            } else {
                return '';
            }
        },
    },
    setup(props, { emit }) {
        const reportLocal = reactive({ ...props.report });
        const recordLocal = reactive({ ...props.record });

        const filters = reactive({
            candidatePicture: true,
            candidateData: true,
            responses: true,

            irregularityTime: true,
            auditResponsable: true,
            auditTime: true,
        });

        const clear = () => {
            filters.candidatePicture = true;
            filters.candidateData = true;
            filters.responses = true;
            filters.irregularityTime = true;
            filters.auditResponsable = true;
            filters.auditTime = true;
        };

        const allQuestions = computed(() =>
            reportLocal.questions
        );

        const respondidas = computed(() =>
            reportLocal.answers.map(item => item.choosedAnswer)
        );

        const letters = ["A", "B", "C", "D", "E", "F"];

        const alerts = computed(() => {
            return recordLocal?.alerts.slice().sort((field1, field2) => {
                if (field1.start! < field2.start!) {
                    return -1;
                }
                if (field1.start! > field2.start!) {
                    return 1;
                }
                return 0;
            });
        });

        return {
            reportLocal,
            formatTime,
            formatDate2,
            respondidas,
            filters,
            allQuestions,
            letters,
            diffTime,
            alerts,
            formatDateTime
        };
    },
});

export default RelatorioTecnicoComp;

