
import { computed, defineComponent, onMounted, reactive, ref, provide } from "vue";
import ReportActionEnum from "@/enums/ReportActionEnum";
import ReportNameEnum from "@/enums/ReportNameEnum";
import { format, set } from "date-fns";
import { ptBR } from "date-fns/locale";
import PrintView from "@/components/PrintView.vue";
import PrintPage from "@/components/PrintPage.vue";
import Irregularities from "./Irregularities.vue";
import Irregularity from "./Irregularity.vue";
import Switch from "@/components/ToogleSwitch.vue";
import { useRoute } from "vue-router";
import ExameService from "@/services/ExameService";
import ExamAnswerApiDTO from "@/dtos/ExamAnswerApiDTO";
import SchedulingDTO from "@/dtos/SchedulingDTO";
import AlertListDTO from "@/dtos/AlertListDTO";
import ImageAlertListDTO from "@/dtos/ImageAlertListDTO";
import ProctoringResponseDTO from "@/dtos/ProctoringResponseDTO";
import { useAlert } from '@/hooks/alert';
import { trackers } from "@/hooks/trackers";
import { formatDate, formatDate2, formatTime, diffTime, formatDateTime } from "@/utils/meus-exames/datetime";
import { ExamReportDTO } from "@/dtos/reports/ExamReportDTO";
import { maska } from "maska";
import Icon from "@/components/Icon.vue";
import RadioButtonBasic from "@/components/RadioButtonBasic.vue";
import vm from "@/viewModels/MainViewModel";
import ReportHistoryService from "@/services/ReportHistoryService";
import RelatorioRegistroRespostas from "./RelatorioRegistroRespostas.vue";
import RelatorioTecnicoComp from "./RelatorioTecnicoComp.vue";



interface ExamReportViewState {
    record: ProctoringResponseDTO | null;
    report: ExamReportDTO | null;
    examAnswer: ExamAnswerApiDTO | null;
    scheduling: SchedulingDTO | null;
    loading: boolean;
    totalObjectives: number;
    objectivesCorrect: number;
}

interface ExamesState {
    alerts: AlertListDTO[];
    imageAlerts: ImageAlertListDTO[];
}

const ExamReportView = defineComponent({
    components: { PrintPage, Irregularities, Irregularity, PrintView, Switch, Icon, RadioButtonBasic, ReportActionEnum, ReportNameEnum, RelatorioRegistroRespostas,RelatorioTecnicoComp },
    directives: { maska },
    methods: {
        getChoosedAnswer(arr: any, index: any) {
            const aux = Object.assign([], arr.choosedAnswers);
            return aux[index - 1];
        },
        getLetters(letters: any, choosedAnswer: any, index: number) {
            var choosedAnswerReturn = letters[choosedAnswer - 1];
            if (choosedAnswerReturn) {
                return choosedAnswerReturn;
            } else {
                return '';
            }
        },
        truncate(data: string, num: any) {
            const reqdString =
                data.split("").slice(0, num).join("");
            return reqdString;
        }
    },
    setup() {
        const { params } = useRoute();
        const alert = useAlert();

        const selectedReport = ref<ReportNameEnum>(ReportNameEnum.none);


        const isMenuOpen = ref(true);
        provide("isMenuOpen", isMenuOpen);

        const toggleReportName = async (name: ReportNameEnum) => {
            selectedReport.value = name;
        };

        const filters = reactive({
            candidatePicture: true,
            candidateData: true,
            responses: true,

            irregularityTime: true,
            auditResponsable: true,
            auditTime: true,
        });

        const alertsState = reactive<ExamesState>({
            imageAlerts: [],
            alerts: []
        });

        const clear = () => {
            filters.candidatePicture = true;
            filters.candidateData = true;
            filters.responses = true;
            filters.irregularityTime = true;
            filters.auditResponsable = true;
            filters.auditTime = true;
        };

        const letters = ["A", "B", "C", "D", "E", "F"];

        const state = reactive<ExamReportViewState>({
            record: null,
            report: null,
            examAnswer: null,
            scheduling: null,
            loading: false,

            totalObjectives: 0,
            objectivesCorrect: 0,
        });

        const allQuestions = computed(() =>
            state.report?.questions
        );


        const respondidas = computed(() =>
            state.report?.answers.map(item => item.choosedAnswer)
        );

        const objectiveAnswers = computed(() =>
            state.report?.questions
        );

        const questions = computed(() => {
            return state.report?.answers;
        });

        const alerts = computed(() => {
            return state.record?.alerts.slice().sort((field1, field2) => {
                if (field1.start! < field2.start!) {
                    return -1;
                }
                if (field1.start! > field2.start!) {
                    return 1;
                }
                return 0;
            });
        });

        const formattedDate = computed(() => {
            return format(new Date('' + state.report?.startDate), "dd / MMMM / yyyy", { locale: ptBR });
        });

        const loadRecord = () => {
            if (state.report?.federativeUnit != "CE") {
                const [request] = ExameService.GetAuditoriaByProctoringId(state.report?.proctoringId as string);
                request
                    .then((resp) => {
                        state.record = resp;
                    })
                    .catch((error) => {
                        alert({ message: error });
                    })
                    .finally(async () => {
                        totalQuestionObjectives();
                        state.loading = false;
                    });
            } else {
                state.loading = false;
            }
        };

        const loadreport = () => {
            const [request] = ExameService.GetExameReport(params.id as string);
            request
                .then((resp) => {
                    state.report = resp;
                })
                .then(() => {
                    loadRecord();
                })
                .catch((error) => {
                    alert({ message: error });
                });
        };

        const totalQuestionObjectives = () => {
            try {
                if (state.report!.questions && state.report!.answers) {
                    state.report?.questions.forEach((item, index) => {
                        state.totalObjectives++;
                        if (Number(state.report?.answers[index]) == item.correctAnswer) {
                            state.objectivesCorrect++;
                        }
                    });
                    trackers.registerLoadExam("", "Sucesso em todal de questões")
                }
            } catch (e: any) {
                trackers.registerError("Erro em questões", e.toString())
                setTimeout(() => {
                    totalQuestionObjectives();
                }, 1000);
                alert({ message: e });
            }
        };

        const readReport = async (relatorio: ReportNameEnum) => {
            state.loading = true;
            try {
                if (!vm.user?.id) throw new Error('Usuário não identificado');

                const [request] = ReportHistoryService.PostReportHistory({
                    examId: params.id.toString(),
                    reportName: relatorio,
                    history: {
                        userId: vm.user.id.toString(),
                        action: ReportActionEnum.Read
                    }
                });
                await request;

                toggleReportName(relatorio);
            
                loadreport();

            } catch (error) {
                state.loading = false;
                alert({
                    title: "Erro ao enviar histórico do relatório",
                    message: error as string,
                    color: "red",
                });
            }
        };

        const printReport = async () => {
            try {
                if (!vm.user?.id) throw new Error('Usuário não identificado');

                if(selectedReport.value == ReportNameEnum.none){
                    alert({
                        message: "Selecione um relatório"
                    })
                    return;
                }

                const [request] = ReportHistoryService.PostReportHistory({
                    examId: params.id.toString(),
                    reportName: selectedReport.value!,
                    history: {
                        userId: vm.user.id.toString(),
                        action: ReportActionEnum.Print
                    }
                });
                await request;

                print();

            } catch (error) {
                state.loading = false;
                alert({
                    title: "Erro ao enviar histórico do relatório",
                    message: error as string,
                    color: "red",
                });
            }
        };


        onMounted(() => {
            if (!document.documentElement.classList.contains("valid-theme") && !document.documentElement.classList.contains("valid-df-theme")) {
                const logoElement = document.querySelector(".container");
                if (logoElement) {
                    (logoElement as HTMLElement).style.height = "50px";
                }
            }
        });

        const print = () => window.print();


        return {
            filters,
            clear,
            print,
            letters,
            state,
            allQuestions,
            questions,
            alerts,
            objectiveAnswers,
            formattedDate,
            alertsState,
            respondidas,
            formatTime,
            formatDate2,
            formatDateTime,
            formatDate,
            diffTime,
            isMenuOpen,
            vm,
            ReportActionEnum,
            ReportNameEnum,
            readReport,
            printReport,
            selectedReport
        };
    },
});

export default ExamReportView;

