
import { computed, defineComponent, reactive, PropType } from "vue";
import { ExamReportDTO } from "@/dtos/reports/ExamReportDTO";
import { formatDate2, formatTime } from "@/utils/meus-exames/datetime";
import RadioButtonBasic from "@/components/RadioButtonBasic.vue";
import { maska } from "maska";


const RelatorioRegistroRespostas = defineComponent({
    components: {
        RadioButtonBasic
    },
    directives: { maska },
    props: {
        report: {
            type: Object as PropType<ExamReportDTO>,
            required: true
        },

    },
    methods: {
        getLetters(letters: any, choosedAnswer: any, index: number) {
            var choosedAnswerReturn = letters[choosedAnswer - 1];
            if (choosedAnswerReturn) {
                return choosedAnswerReturn;
            } else {
                return '';
            }
        },
    },
    setup(props, { emit }) {
        const reportLocal = reactive({ ...props.report });

        const filters = reactive({
            candidatePicture: true,
            candidateData: true,
            responses: true,

            irregularityTime: true,
            auditResponsable: true,
            auditTime: true,
        });

        const clear = () => {
            filters.candidatePicture = true;
            filters.candidateData = true;
            filters.responses = true;
            filters.irregularityTime = true;
            filters.auditResponsable = true;
            filters.auditTime = true;
        };

        const allQuestions = computed(() =>
            reportLocal.questions
        );

        const respondidas = computed(() =>
            reportLocal.answers.map(item => item.choosedAnswer)
        );

        const letters = ["A", "B", "C", "D", "E", "F"];

        return {
            reportLocal,
            formatTime,
            formatDate2,
            respondidas,
            filters,
            allQuestions,
            letters
        };
    },
});

export default RelatorioRegistroRespostas;
