
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'RadioButtonBasic',
    props: {
        modelValue: {
            type: String,
            required: true
        },
        radioValue: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isCorrect: {
            type: Boolean,
            default: false
        },
        isSelected: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        // Verifica se o botão de rádio está marcado
        isChecked(): boolean {  // Especificando o tipo de retorno como boolean
            return this.modelValue === this.radioValue;
        },
        borderColor(): string {
            if (this.isCorrect) {
                return 'rgba(4, 152, 109, 1)';  // Borda verde quando correta
            }
            if (this.isSelected) {
                return '#fa1b1b';  // Borda vermelha quando selecionada
                //return 'rgba(195, 195, 195, 1)';
            }
            return 'rgba(195, 195, 195, 1)'; // Borda padrão
        },
        circleBackgroundColor(): string {
            if (this.isCorrect) {
                return 'rgba(4, 152, 109, 1)';  // Fundo verde quando correta
            }
            if (this.isSelected) {
                return '#fa1b1b';  // Fundo vermelho quando selecionada
                //return 'rgba(195, 195, 195, 1)';
            }
            return '#E8E8E8'; // Cor padrão do fundo
        }
    }
});
